export default class ScrollHelper {

    static bringIntoView(element: HTMLElement, useNative = true) {
        if (useNative) {
            element.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "center"
            });
            return;
        }

        let parent = (element.assignedSlot ?? element).parentElement;
        while(parent && parent.scrollWidth === parent.clientWidth) {
            element = parent;
            parent = parent.parentElement;
        }
        if (!parent) {
            return;
        }

        const left = element.offsetLeft - parent.offsetLeft - (parent.clientWidth - element.offsetWidth) / 2;
        parent.scrollTo({
            left,
            top: 0,
            behavior: "smooth"
        });
    }

}