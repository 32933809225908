import { reveal } from "../../animations/reveal/reveal";
import { ComponentHelper } from "../../core/ComponentHelper";
import ImageTagHelper from "../../core/ImageTagHelper";
import Modules from "../../core/Modules";
import TemplateControl from "../../core/TemplateControl";
import { XNode } from "../../core/XNode";
import { styled } from "../../core/core";
import { installCss } from "../../core/installCss";

let id = 1;

    styled.css `

        display: inline-block;

        &::part(root) {
            display: grid;
            grid-template-columns: auto 1fr auto;
            grid-template-rows: auto 1fr auto;
            align-items: center;
        }

        &::part(left) {
            grid-row: 2;
            grid-column: 1;
            border: none;
            outline: none;
            cursor: pointer;
            box-sizing: border-box;
            margin: 10px;
            font: var(--fa-font-solid);
            font-size: 2rem;
            color: white;
            text-shadow: 0 0 3px black;
            z-index: 2;
            &::after {
                content: "\uf137"
            }
        }

        &::part(right) {
            grid-row: 2;
            grid-column: 3;
            cursor: pointer;
            box-sizing: border-box;
            margin: 10px;
            font: var(--fa-font-solid);
            font-size: 2rem;
            z-index: 2;
            color: white;
            text-shadow: 0 0 3px black;
            &::after {
                content: "\uf138"
            }
        }

        &::part(content) {
            grid-row: 1 / span 3;
            grid-column: 1 / span 3;
            color: unset;
            display: flex;
            flex-wrap: nowrap;
            gap: 5px;
            align-content: center;
            justify-content: space-between;
            padding: 10px;
            margin: 10px;
            border-radius: 10px;
            align-items: center;
            justify-items: stretch;
            overflow-y: hidden;
            overflow-x: auto;
            scrollbar-width: none;
            scroll-snap-type: x mandatory;
        }

        &::part(img) {
            display: inline-block;
            padding: 5px;
            scroll-snap-align: center;
        }

        & img {
            max-height: 20vh;
            margin: 20px;
            box-shadow: 0 0 20px 1px black;
            border: solid 1px white;
            &:hover {
                box-shadow: 0 0 20px 1px blue;
            }
        }

    `.installGlobal("image-carousel");

let imgID = 1;

class ImageCarousel extends TemplateControl {

    container: HTMLElement;

    contentClick = (e: MouseEvent) => {
        // console.log(e);
        let start = e.target as HTMLElement;
        while (start && !start.assignedSlot) {
            start = start.parentElement;
        }
        if (!start) {
            return;
        }
        const fullScreen = document.createElement("full-screen-image-gallery");

        const { children } = this;

        let selected = null;

        for (let index = 0; index < children.length; index++) {
            const element = children[index];
            const newChild = element.cloneNode(true) as HTMLElement;
            if (element === start) {
                selected = newChild;
            }
            if (newChild.tagName === "IMG") {
                const { full } = ImageTagHelper.getUrls(newChild);
                newChild.setAttribute("src", full);
            }
            fullScreen.appendChild(newChild);
        }
        fullScreen.style.position = "fixed";
        fullScreen.style.width = "100%";
        fullScreen.style.height = "100%";
        (fullScreen as any).selected = selected;
        document.body.appendChild(fullScreen);
    };

    async prepare() {

        this.id ||= `img_carousel_${id++}`;

        const root = this.attachShadow({ mode: "open", delegatesFocus: true });


        XNode.append(root, <div part="root">
            <div part="left" event-click={() => this.move(-1)}/>
            <div part="content" event-click={this.contentClick}/>
            <div part="right" event-click={() => this.move(1)}/>
        </div>);

        this.container = root.querySelector(`[part="content"]`)
    }
    
    move(n: number) {
        const { container } = this;

        container.scrollBy({
            left: n * container.offsetWidth,
            behavior: "smooth"
        });
    }

    onChildAdded(child: HTMLElement) {

        const dataOnReveal = this.getAttribute("data-on-reveal") ?? "zoom-in";

        const name = `ic1-id-${imgID++}`;
        XNode.append(this.container, <slot part="img" name={name} />);

        if (child.tagName === "IMG") {
            const {thumb } = ImageTagHelper.getUrls(child);
            child.setAttribute("src", thumb);
        }
        child.setAttribute("slot", name);
        child.setAttribute("data-on-reveal", dataOnReveal);
        reveal.observe(child);
    }
    onChildRemoved(child: Node) {
    }



}

customElements.define("image-carousel", ImageCarousel);