import HtmlControl from "../../core/HtmlControl";
import ImageTagHelper from "../../core/ImageTagHelper";
import TemplateControl from "../../core/TemplateControl";
import { styled } from "../../core/core";

let id = 1;

    styled.css `
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        align-content: center;
        justify-content: space-between;
        padding: 10px;
        margin: 10px;
        border-radius: 10px;
        align-items: center;
        justify-items: stretch;
        flex-wrap: wrap;
        & > img {
            max-height: 20vh;
            margin: 20px;
            box-shadow: 0 0 20px 1px black;
            border: solid 1px white;
        }
        padding: 5px;
        &:hover > img {
            box-shadow: 0 0 20px 1px blue;
        }

        @media (max-width: 500px) {
            & > img {
                max-width: 25vh;
            }
        }
    `.installGlobal("image-grid");

class ImageGrid extends TemplateControl {

    onChildAdded(child: HTMLElement) {
        const dataOnReveal = this.getAttribute("data-on-reveal") ?? "zoom-in";
        child.setAttribute("data-on-reveal", dataOnReveal);

        if (child.tagName === "IMG") {
            const {thumb } = ImageTagHelper.getUrls(child);
            child.setAttribute("src", thumb);
        }
    }
    onChildRemoved(child: Node) {
    }

    onClickEvent = (e: MouseEvent) => {
        let start = e.target as HTMLElement;
        while(start && !/^(video|img)/i.test(start.tagName)) {
            start = start.parentElement;
        }
        if (!start) {
            return;
        }

        const fullScreen = document.createElement("full-screen-image-gallery");

        const { children } = this;

        let selected = null;

        for (let index = 0; index < children.length; index++) {
            const element = children[index];
            const newChild = element.cloneNode(true) as HTMLElement;
            if (element === start) {
                selected = newChild;
            }
            if (newChild.tagName === "IMG") {
                const { full } = ImageTagHelper.getUrls(newChild);
                newChild.setAttribute("src", full);
            }
            fullScreen.appendChild(newChild);
        }
        fullScreen.style.position = "fixed";
        fullScreen.style.width = "100%";
        fullScreen.style.height = "100%";
        (fullScreen as any).selected = selected;
        document.body.appendChild(fullScreen);
    };

    async prepare() {

        this.id ||= `img_grid_${id++}`;

        this.addEventListener("click", this.onClickEvent);
    }

}

customElements.define("image-grid", ImageGrid);