import { ComponentHelper } from "../../core/ComponentHelper";
import ScrollHelper from "../../core/ScrollHelper";
import TemplateControl from "../../core/TemplateControl";
import { XNode } from "../../core/XNode";
import { styled } from "../../core/core";

    styled.css `

        display: grid !important;
        box-sizing: border-box;
        grid-template-columns: auto 1fr auto;
        grid-template-rows: auto 1fr auto;
        position: relative;

        & > * {
        }


        & * {
            box-sizing: border-box;
        }

        &::part(left) {
            grid-row: 1 / span 3;
            grid-column: 1;
            font-size: 2rem;
            place-self: center;
            border: none;
            outline: none;
            background-color: transparent;
            cursor: pointer;
            font: var(--fa-font-solid);
            font-size: 2rem;
            z-index: 2;
            color: white;
            text-shadow: 0 0 3px black;
            &::after {
                content: "\uf137"
            }
        }

        &::part(right) {
            grid-row: 1 / span 3;
            grid-column: 3;
            font-size: 2rem;
            place-self: center;
            border: none;
            outline: none;
            background-color: transparent;
            cursor: pointer;
            font: var(--fa-font-solid);
            font-size: 2rem;
            z-index: 2;
            color: white;
            text-shadow: 0 0 3px black;
            &::after {
                content: "\uf138"
            }
        }

        &[marker=off], &[marker=false] {
            &::part(left) {
                display: none;
            }
            &::part(right) {
                display: none;
            }

        }

        &::part(canvas) {
            grid-row: 1 / span 3 ;
            grid-column: 1 / span 3;
            display: grid;
            position: relative;
            grid: repeat(1, 1fr) / auto-flow 100%;
            column-gap: 10px;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow-y: hidden;
            overflow-x: auto;
            scrollbar-width: none;
            scroll-snap-type: x mandatory;
        }

        &::part(child) {
            grid-row: 1;
            scroll-snap-align: center;
            scroll-snap-stop: always;
            display: inline-block;
            width: 100%;
            height: 100%;
        }

        &::part(indicator) {
            grid-row: 3 ;
            grid-column: 2;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            gap: 5px;
            min-height: 30px;
            z-index: 2;
        }

        &::part(circle) {
            cursor: pointer;
            font-size: 0.6rem;
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke-color: currentColor;
            -webkit-text-stroke-width: 1px;
            &::before {
                content: "⚫";
            }
        }
        &::part(active)::before {
            content: "⚪";    
        }

    `.installGlobal("carousel-slider");

let partId = 1;

const added = Symbol("added");

class CarouselSlider extends TemplateControl {

    static observedAttributes = ["animate", "duration"];

    current: HTMLElement;

    indicator: HTMLElement;
    canvas: HTMLElement;

    intersectionObserver: IntersectionObserver;
    root: ShadowRoot;

    timer: any;

    async onFirstPrepare() {
        this.setupAnimation();
    }

    setupAnimation() {
        const { timer } = this;
        if (timer) {
            clearInterval(timer)
        }
        const duration = parseInt(this.getAttribute("duration") || "5000",10);
        if (!/yes|true/i.test(this.getAttribute("animate"))) {
            return;
        }
        this.timer = setInterval(() => this.rotate(), duration);
    }

    attributeChangedCallback(name, oldValue, newValue) {
        switch(name) {
            case "animate":
            case "duration":
                this.setupAnimation();
                break;
        }
    }

    connectedCallback() {
        this.intersectionObserver = new IntersectionObserver((entries) => {
            try {
                let max = null as IntersectionObserverEntry;
                for (const element of entries) {
                    if (element.isIntersecting) {
                        if (max === null) {
                            max = element;
                        } else {
                            if (max.intersectionRatio < element.intersectionRatio) {
                                max = element;
                            }
                        }
                    }
                }
                if (max) {
                    requestIdleCallback(() => {
                        // update the selected index...
                        const itemIndex = max.target.getAttribute("item-index");
                        const { children } = this.indicator;
                        for (let index = 0; index < children.length; index++) {
                            const element = children[index];
                            const ei = element.getAttribute("item-indicator-index");
                            element.setAttribute("part", itemIndex === ei
                                ? "circle active"
                                : "circle");
                        }

                        // rotate the items...
                        const { target } = max;

                        // if there is nothing on right.. bring from first
                        // if there is nothing on left.. bring from last
                        // works only if there are more than 3 items...
                        if (this.childElementCount <= 3) {
                            return;
                        }

                        const parent = target.parentElement;

                        if(!target.nextElementSibling?.nextElementSibling) {
                            // bring first...
                            const first = parent.firstElementChild as HTMLElement;
                            first.remove();
                            parent.appendChild(first);
                        }
                        if (!target.previousElementSibling?.previousElementSibling) {
                            const last = parent.lastElementChild as HTMLElement;
                            last.remove();
                            parent.insertAdjacentElement("afterbegin", last);
                        }


                    });
                }
            } catch (error) {
                console.error(error);
            }
        }, { threshold: 0.8, root: this });
        super.connectedCallback();
    }

    async onAfterConnected() {
        const { children } = this;
        for (let index = 0; index < children.length; index++) {
            const element = children[index];
            this.intersectionObserver.observe(element);
        }
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this.intersectionObserver.disconnect();
    }


    indicatorClick = (e: MouseEvent) => {
        // console.log(e);
        let { target, value } = ComponentHelper.getParentAttribute(e.target as HTMLElement, (x) => x.getAttribute("item-indicator-index"));
        if (!value) {
            return;
        }
        const slot = this.canvas.querySelector(`[item-index="${value}"]`) as HTMLElement;
        this.current = slot;
        ScrollHelper.bringIntoView(this.current, true);
    };

    async prepare() {
        const root = this.attachShadow({ mode: "open"})
        this.root = root;
        XNode.render(root, <div>
            <i
                event-click={() => this.moveLeft() }
                part="left"></i>
            <div part="canvas"></div>
            <div part="indicator" event-click={this.indicatorClick}></div>
            <i
                event-click={() => this.moveRight() }
                part="right"></i>
        </div>);

        const indicator = root.querySelector(`[part="indicator"]`) as HTMLElement;
        this.indicator = indicator;
        const canvas = root.querySelector(`[part="canvas"]`) as HTMLElement;
        this.canvas = canvas;
    }

    onChildAdded(iterator: HTMLElement) {

        if (iterator[added]) {
            return;
        }
        iterator[added] = true;

        const child = this.ownerDocument.createElement("slot");
        const { canvas, indicator, current } = this;
        if (!current) {
            this.current = child;
        }

        const i = partId++;
        const name = `child${i}`;
        child.setAttribute("name", name);
        child.setAttribute("part", "child");
        child.setAttribute("item-index", i as any as string);
        canvas.appendChild(child);
        iterator.slot = name;
        const span = this.ownerDocument.createElement("span");
        span.setAttribute("part", "circle");
        span.setAttribute("item-indicator-index", i as any as string);
        indicator.appendChild(span);
        span.addEventListener("click", () => {
            this.current = iterator;
            this.current.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start"});
        });
        this.intersectionObserver.observe(child);
    }

    onChildRemoved(child: Node) {
        
    }

    moveLeft() {
        const previous = this.current.previousElementSibling as HTMLElement;
        if (!previous) {
            return;
        }
        this.current = previous;
        ScrollHelper.bringIntoView(this.current, true);
    }

    moveRight() {
        const next = this.current.nextElementSibling as HTMLElement;
        if (!next) {
            return;
        }
        this.current = next;
        ScrollHelper.bringIntoView(this.current, true);
    }

    
    rotate() {
        let next = this.current.nextElementSibling as HTMLElement;
        if (!next) {
            next = this.current.parentElement.firstElementChild as HTMLElement;
            if (!next) {
                return;
            }
        }
        this.current = next;
        ScrollHelper.bringIntoView(this.current, true);
    }

}

customElements.define("carousel-slider", CarouselSlider);
