import { ComponentHelper } from "../../core/ComponentHelper";
import { styled } from "../../core/core";
import { installCss } from "../../core/installCss";


    styled.css `
    
    display: inline-flex;
    gap: 10px;

    padding: 5px;

    & > select {
        outline: none;
        border: none;
        background-color: transparent;
        cursor: pointer;
    }
    
    `.installGlobal("language-chooser");

let langID = 1;

const getName = (code: string) => {
    try {
        return (new Intl.DisplayNames([code], { type: 'language' })).of(code);
    } catch (error) {
        return code;
    }
};

const installLanguageStyle = (code: string) => {

    if(document.querySelector(`[data-lang-selector=${code}]`)) {
        return;
    }

    const style = document.createElement("style");
    style.setAttribute("data-lang-selector", code);
    style.textContent = `body[data-lang=${code}] *[lang]:not([lang=${code}]) { display: none; }`;
    document.head.appendChild(style);


};

export default class LanguageChooser extends HTMLElement {

    initialized = false;

    connectedCallback() {

        this.init().catch(console.log);
        
    }

    async init() {

        if (this.initialized) {
            return;
        }
        this.initialized = true;

        await ComponentHelper.waitForReady();

        let langs = this.getAttribute("languages");

        if (!langs) {
            // detect langs...
            console.warn(`Attribute languages not set, set to disable language detection.`)
            const ls = new Set();
            for (const element of Array.from(document.querySelectorAll(`[lang]`))) {
                const l = element.getAttribute("lang");
                ls.add(l.trim().toLocaleLowerCase());
            }
            langs = Array.from(ls).join(",");
        }

        const select = document.createElement("select");

        let defaultLanguage = document.body.getAttribute("data-lang");

        // we will set body's language if not set...
        for (const element of langs.split(/[,\s\;]/i)) {
            const lang = element.trim();
            if (!lang) {
                continue;
            }
            installLanguageStyle(lang);

            const option = document.createElement("option");
            option.value = lang;
            option.textContent = getName(lang);
            select.appendChild(option);

            if (!defaultLanguage) {
                defaultLanguage = lang;
                document.body.setAttribute("data-lang", lang);
            }
        }

        const selected = localStorage.getItem("website-lang");
        if (selected) {
            select.value = selected;
        }

        select.oninput = () => {
            document.body.setAttribute("data-lang", select.value);
            localStorage.setItem("website-lang", select.value);
        };

        const icon = document.createElement("label");
        icon.className = "fa-solid fa-globe";
        icon.htmlFor = (select.id ||= `lang-chooser-id-${langID++}`);
        this.appendChild(icon);
        this.appendChild(select);
    }


}

customElements.define("language-chooser", LanguageChooser);
